const imageUrl = 'https://waxjr.xiaojur.com:9001/waxjr';
const filePathFormat= function (path) {
  if (path != null && path != "" && path.length > 0) {
    return imageUrl + '/' + path;
  }
  return '';
}
const fileType = (string) => {
	let filetype = string.substring(string.length-3,string.length);
	console.log(filetype,'文档类型');
	if(filetype == "pdf"){
		return true;
	}else{
		return false;
	}
}

const split = (string,QNum,Hnum) => {
	if(string!=''&&string!=null&&string!=undefined){
		if(string.length>30){
			var jiequ = string.substring(QNum,Hnum)+'...';
		}else{
			var jiequ = string.substring(QNum,Hnum);
		}
	}
	return jiequ
}
module.exports = {
    filePathFormat,
    fileType,
    imageUrl,
    split
}
