<template>
    <!-- 登录弹窗 -->
    <div class="login" v-if="$store.state.loginMaskShow">
      <div class="kuang">
        <div class="logo">
          <div class="img">
            <img class="bgImage" src="@/images/login/login_mask_logo.png" alt="">
          </div>
        </div>
        <div class="form">
          <div class="title">学员登录</div>
          <div class="inputBox" style="margin-top:3.125vw;" :class="accountActive?'inputBoxActive':''">
            <div class="icon">
              <img class="bgImage" src="@/images/login/account.png" alt="" />
            </div>
            <input class="account" ref="account" @focus="inputFocus('account')" @blur="inputBlur('account')" type="text" v-model="loginForm.account" placeholder="请输入用户账号" />
          </div>
          <div class="inputBox" style="margin-top:2.6vw;" :class="passwordActive?'inputBoxActive':''">
            <div class="icon">
              <img class="bgImage" src="@/images/login/password.png" alt="" />
            </div>
            <input class="password" ref="password" @focus="inputFocus('password')" @blur="inputBlur('password')" @keyup.enter="handleLogin" type="password" v-model="loginForm.password" placeholder="请输入密码" />
          </div>
          <el-button :loading="loading" class="loginBtn" @click.prevent="handleLogin">登录</el-button>
        </div>
        <div class="close iconfont icon-close" @click="closeClick"></div>
      </div>
    </div>
</template>


<script>
export default {
    data(){
        return {
            loginForm:{
                account:'',
                password:''
            },
            accountActive:false,
            passwordActive:false,
            // loading:this.$store.state.loading
        }
    },
    mounted(){
        
    },
    computed:{
        loading(){
           return this.$store.state.loading;
        }
    },
    methods:{
        // 表单获取焦点
        inputFocus(tag){
            if(tag == 'account'){
                this.accountActive = true;
            }else{
                this.passwordActive = true;
            }
        },
        // 表单失焦
        inputBlur(tag){
            if(tag == 'account'){
                this.accountActive = false;
            }else{
                this.passwordActive = false;
            }
        },
        // 登录
        handleLogin(){
            if(this.loginForm.account == ''){
                this.$message({
                    message:'请填写账号',
                    type: 'warning'
                })
                this.$refs.account.focus();
                return false;
            }
            if(this.loginForm.password == ''){
                this.$message({
                    message:'请填写密码',
                    type:'warning'
                })
                this.$refs.password.focus();
                return false;
            }
            this.$store.dispatch('user/login', this.loginForm).then(() => {
                this.$store.commit('setLoginMaskShow',false);
                this.$store.commit('setLoginState',true);
                // this.$router.push({ path: this.redirect || '/' })
                // this.$router.push({path:'/'})
                // this.loading = false;
                location.reload();
            }).catch(() => {
                this.$store.commit('setLoading',false);
            })
        },
        closeClick(){
            this.$store.commit('setLoginMaskShow',false);
        }
    }
}
</script>

<style lang="less" scoped>
.login{
    width:100vw;height:100vh;
    position: fixed;
    left:0;top:0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:29999;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0.5vw);
    .kuang{
        width: 62.5vw;
        height: 31.25vw;
        background: #FFFFFF;
        box-shadow: 0 0.2vw 1.04vw 0.3vw rgba(209, 209, 209, 0.5);
        border-radius: 0.41vw;
        display: flex;
        align-items: center;
        position: relative;
        .logo{
            width:31.25vw;height:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #F9F9F9;
            // background-color: orange;
            .img{
            width:26.09vw;height:21.82vw;
            position: relative;
            }
        }
        .form{
            width:31.25vw;height:100%;
            background-color: #fff;
            padding:0 3.07vw;
            box-sizing:border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            // background-color: green;
            .title{
            width:100%;height:1.92vw;
            font-size: 1.35vw;
            font-weight: 500;
            color: #333333;
            line-height: 1.92vw;
            margin-top:5.15vw;
            text-align: center;
            // background-color: orange;
            }
            .inputBox{
                width:100%;height:2.91vw;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:0 0.72vw;
                box-sizing: border-box;
                border-radius: 0.5vw;
                background: #F6F6F9;
                border: 1px solid transparent !important;
                transition: .3s;
            .icon{
                width:1.25vw;height:1.3vw;
                border-radius: 50%;
                position: relative;
            }
            .account,
            .password{
                width:90%;height:100%;
                outline: none;
                border: none;
                background-color: transparent;
                font-size:1.04vw;
                color:#000;
            }
            }
            .inputBoxActive{
                border: 1px solid #2A6DFF !important;
                background-color: #fff !important;
            }
            .loginBtn{
                width: 100%;height: 2.91vw;
                background: #2A6DFF;
                border-radius: 0.5vw;
                color:#fff;
                font-size:1.14vw;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top:4.16vw;
                position: relative;
                cursor: pointer;
            }
        }
        .close{
            width:1.5vw;height:1.5vw;
            border-radius:50%;
            background-color: #2A6DFF;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right:0;top:0;
            color:#fff;
            font-size:1vw;
            transform: translateY(-50%)translateX(50%);
            cursor: pointer;
            transition: 0.3s;
        }
        .close:hover{
            top:1px;
        }
    }
}
</style>