<template>
    <div class="container">
        
    </div>
</template>



<script>
export default {
    data(){
        return {
            
        }
    },
    mounted(){
       
    },
    created(){

    },
    methods:{
       
    }
}
</script>


<style scoped>

</style>