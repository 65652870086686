<template>
  <div class="footer">
    <img class="bgImage" src="@/images/footer/footer_1.png" alt="" />
  </div>
</template>


<script>
export default {
    data(){
        return {
          
        }
    },
    mounted(){
      
    },
    methods: {
    }
}
</script>




<style lang="less" scoped>
.footer{
  width:100%;height:17.86vw;
  position: relative;
  img{
    pointer-events: none;
  }
}
</style>