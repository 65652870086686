import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: "/safe-bjxf", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 3000000 // request timeout
})


// request interceptor
service.interceptors.request.use(

  config => {
    // do something before request is sent
    // console.log(store.getters.token,'OPOLOLOLO');
    let token = getToken();
    // console.log(token,'yyuyuyuy');
    if (token) {
      // console.log('加tokenPPP{{{')
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Access-Token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)



const err = (error) => {
  if (error.response) {
    let data = error.response.data
    const token = getToken();
    console.log("------异常响应------",token)
    console.log("------异常响应------",error.response.status)
    switch (error.response.status) {
      case 403:
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 4
        })
        break
      case 500:
        console.log('报500了');
        console.log(data,'data>>>>M');
        //notification.error({ message: '系统提示', description:'Token失效，请重新登录!',duration: 4})
        if(token && data.message.indexOf("Token") != -1){
          // update-begin- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
          // store.dispatch('Logout').then(() => {
          //     window.location.reload()
          // })
          console.log('token失效——————————————————');
          // alert('token失效')
          MessageBox.alert('登录已过期，请重新登录', {
            confirmButtonText: '重新登录',
            // cancelButtonText: '取消',
            type: 'warning',
            showClose:false,
            showCancelButton:false
          }).then(() => {
            // store.dispatch('user/resetToken').then(() => {
            //   location.reload()
            // })
            store.commit('setLoginMaskShow',true);
          })
          // Modal.error({
          //   title: '登录已过期',
          //   content: '很抱歉，登录已过期，请重新登录',
          //   okText: '重新登录',
          //   mask: false,
          //   onOk: () => {
          //     store.dispatch('Logout').then(() => {
          //       Vue.ls.remove(ACCESS_TOKEN)
          //       window.location.reload()
          //     })
          //   }
          // })
          // update-end- --- author:scott ------ date:20190225 ---- for:Token失效采用弹框模式，不直接跳转----
        }
        break
      case 404:
        Message({
          message: "很抱歉，资源未找到!",
          type: 'error',
          duration: 4
        })
        break
      case 504:
        Message({
          message: "网络超时",
          type: 'error',
          duration: 4
        })
        break
      case 401:
        Message({
          message: "未授权，请重新登录",
          type: 'error',
          duration: 4
        })
        if (token) {
          // store.dispatch('user/logout').then(() => {
          //   setTimeout(() => {
          //     window.location.reload()
          //   }, 1500)
          // })
          store.commit('setLoginMaskShow',true);
        }
        break
      default:
         Message({
          message: data.message,
          type: 'error',
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
};




service.interceptors.response.use((response) => {
  return response.data
}, err)




























// response interceptor
// service.interceptors.response.use(

//   response => {
//     const res = response.data

//     // if the custom code is not 20000, it is judged as an error.
//     if (res.code !== 200) {
//       Message({
//         message: res.message || 'Error',
//         type: 'error',
//         duration: 5 * 1000
//       })

//       // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
//       if (res.message !='' && res.message.indexOf('Token') != -1) {
//         // to re-login
//         MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
//           confirmButtonText: 'Re-Login',
//           cancelButtonText: 'Cancel',
//           type: 'warning'
//         }).then(() => {
//           store.dispatch('user/resetToken').then(() => {
//             location.reload()
//           })
//         })
//       }
//       return Promise.reject(new Error(res.message || 'Error'))
//     } else {
//       return res
//     }
//   },
//   error => {
//     console.log(error,'dddd');
//     if (res.message !='' && res.message.indexOf('Token') != -1) {
//       // to re-login
//       MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
//         confirmButtonText: 'Re-Login',
//         cancelButtonText: 'Cancel',
//         type: 'warning'
//       }).then(() => {
//         store.dispatch('user/resetToken').then(() => {
//           location.reload()
//         })
//       })
//     }else{
//       console.log('err...' + error) // for debug
//       Message({
//         message: error.message,
//         type: 'error',
//         duration: 5 * 1000
//       })
//       return Promise.reject(error)
//     }
//   }
// )

export {
  service as axios
}
