import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



// 引入layout总模板
import Layout from '@/layout'

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden:true
  },
  // {
  //   path: '/404',
  //   component: () => import('@/views/404'),
  //   hidden: true
  // },
  {
    path: '/',
    component:Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      name: 'Home',
      component: () => import('@/views/index/index'),
      meta:{
        tag:'shouye',
        islogin:false
      }
    },{
      path: 'yunzhibo',
      name: 'Yunzhibo',
      component: () => import('@/views/index/yunzhibolist'),
      meta:{
        tag:'shouye',
        islogin:false
      }
    }]
  },
  {
    path: '/news',
    component:Layout,
    redirect: '/news/index',
    children: [{
      path: 'index',
      name: 'News',
      component: () => import('@/views/news/index'),
      meta:{
        tag:'xinwen',
        islogin:false
      }
    }]
  },
  {
    path: '/fangtan',
    component:Layout,
    redirect: '/fangtan/index',
    children: [{
      path: 'index',
      name: 'Fangtan',
      component: () => import('@/views/fangtan/index'),
      meta:{
        tag:'fangtan',
        islogin:false
      }
    },{
      path: 'yunzhibo',
      name: 'fantanYunzhibo',
      component: () => import('@/views/fangtan/yunzhibolist'),
      meta:{
        tag:'fangtan',
        islogin:true
      }
    }]
  },
  {
    path: '/shipin',
    component:Layout,
    redirect: '/shipin/index',
    children: [{
      path: 'index',
      name: 'Shipin',
      component: () => import('@/views/shipin/index'),
      meta:{
        tag:'shipin',
        islogin:false
      }
    }]
  },
  {
    path: '/wenku',
    component:Layout,
    redirect: '/wenku/index',
    children: [{
      path: 'index',
      name: 'Wenku',
      component: () => import('@/views/wenku/index'),
      meta:{
        tag:'wenku',
        islogin:false
      }
    }]
  },
  {
    path: '/zhuangbei',
    component:Layout,
    redirect: '/zhuangbei/index',
    children: [{
      path: 'index',
      name: 'Zhuangbei',
      component: () => import('@/views/zhuangbei/index'),
      meta:{
        tag:'zhuangbei',
        islogin:false
      }
    },{
      path: 'product',
      name: 'product',
      component: () => import('@/views/zhuangbei/product'),
      meta:{
        tag:'zhuangbei',
        islogin:false
      }
    },{
      path: 'productdetail',
      name: 'Productdetail',
      component: () => import('@/views/zhuangbei/productdetail'),
      meta:{
        tag:'zhuangbei',
        islogin:false
      }
    },{
      path: 'gsDeatil',
      name: 'gsDeatil',
      component: () => import('@/views/zhuangbei/gsDeatil'),
      meta:{
        tag:'zhuangbei',
        islogin:false
      }
    },{
      path: 'yzgs',
      name: 'yzgs',
      component: () => import('@/views/zhuangbei/yzgs'),
      meta:{
        tag:'zhuangbei',
        islogin:false
      }
    }]
  },
  {
    path: '/zhuanjia',
    component:Layout,
    redirect: '/zhuanjia/index',
    children: [{
      path: 'index',
      name: 'Zhuanjia',
      component: () => import('@/views/zhuanjia/index'),
      meta:{
        tag:'zhuanjia',
        islogin:true
      }
    }]
  },
  {
    path: '/guanyu',
    component:Layout,
    redirect: '/guanyu/index',
    children: [{
      path: 'index',
      name: 'Guanyu',
      component: () => import('@/views/guanyu/index'),
      meta:{
        tag:'guanyu',
        islogin:false
      }
    }]
  },
  {
    path: '/xiazai',
    component:Layout,
    redirect: '/xiazai/index',
    children: [{
      path: 'index',
      name: 'Xiazai',
      component: () => import('@/views/xiazai/index'),
      meta:{
        tag:'xiazai',
        islogin:false
      }
    }]
  },
  {
    path: '/ketiku',
    component:Layout,
    redirect: '/ketiku/index',
    children: [{
      path: 'index',
      name: 'Ketiku',
      component: () => import('@/views/ketiku/index'),
      meta:{
        tag:'ketiku',
        islogin:false
      }
    },{
      path: 'detail',
      name: 'Detail',
      component: () => import('@/views/ketiku/detail'),
      meta:{
        tag:'ketiku',
        islogin:true
      }
    },{
      path: 'datika',
      name: 'datika',
      component: () => import('@/views/ketiku/dati/datika'),
      meta:{
        tag:'ketiku',
        islogin:true
      }
    },{
      path: 'lianxi',
      name: 'lianxi',
      component: () => import('@/views/ketiku/dati/lianxi'),
      meta:{
        tag:'ketiku',
        islogin:true
      }
    }]
  },
  {
    path: '/kaoshi',
    component:Layout,
    redirect: '/kaoshi/index',
    children: [{
      path: 'index',
      name: 'kaoshi',
      component: () => import('@/views/kaoshi/index'),
      meta:{
        tag:'kaoshi',
        islogin:true
      }
    },{
      path: 'lianxi',
      name: 'lianxi',
      component: () => import('@/views/kaoshi/dati/lianxi'),
      meta:{
        tag:'kaoshi',
		    islogin:true
      }
    },{
      path: 'datika',
      name: 'datika',
      component: () => import('@/views/kaoshi/dati/datika'),
      meta:{
        tag:'kaoshi',
		    islogin:true
      }
    }]
  },
  {
    path: '/jianguan',
    component:Layout,
    redirect: '/jianguan/index',
    children: [{
      path: 'index',
      name: 'Jianguan',
      component: () => import('@/views/jianguan/index'),
      meta:{
        tag:'jianguan',
        islogin:true
      }
    }]
  },
































  {
    path: '/live',
    component: Layout,
    redirect: '/live/index',
    children: [{
      path: 'index',
      name: 'Live',
      component: () => import('@/views/live/index'),
      meta:{
        tag:'meiridati',
        islogin:true
      }
    },{
      path: 'jiexi',
      name: 'Jiexi',
      component: () => import('@/views/live/datika/index'),
      meta:{
        tag:'meiridati',
        islogin:true
      }
    }]
  },
  {
    path:'/gkk',
    component: Layout,
    redirect: '/gkk/index',
    children: [{
      path: 'index',
      name: 'gkk',
      component: () => import('@/views/gkk/index'),
      meta:{
        tag:'guokaoku',
        islogin:true
      }
    },{
      path: 'list',
      name: 'list',
      component: () => import('@/views/gkk/list'),
      meta:{
        tag:'guokaoku',
        islogin:true
      }
    },{
      path: 'detail',
      name: 'gkkDetail',
      component: () => import('@/views/gkk/detail'),
      meta:{
        tag:'guokaoku',
        islogin:true
      }
    },{
      path: 'lianxi',
      name: 'gkkLianxi',
      component: () => import('@/views/gkk/dati/lianxi'),
      meta:{
        tag:'guokaoku',
        islogin:true
      }
    },{
      path: 'jiexi',
      name: 'jiexi',
      component: () => import('@/views/gkk/dati/datika'),
      meta:{
        tag:'guokaoku',
        islogin:true
      }
    }]
  },
  {
    path: '/jingsai',
    component: Layout,
    redirect: '/jingsai/index',
    children: [{
      path: 'index',
      name: 'Jingsai',
      component: () => import('@/views/jingsai/index'),
      meta:{
        tag:'zhishijingsai',
        islogin:true
      }
    },{
      path: 'detail',
      name: 'jinsaiDetail',
      component: () => import('@/views/jingsai/detail'),
      meta:{
        tag:'zhishijingsai',
        islogin:true
      }
    },{
      path: 'exam',
      name: 'exam',
      component: () => import('@/views/jingsai/exam'),
      meta:{
        tag:'zhishijingsai',
        islogin:true
      }
    },{
      path: 'jiexi',
      name: 'jinsaiJiexi',
      component: () => import('@/views/jingsai/jiexi'),
      meta:{
        tag:'zhishijingsai',
        islogin:true
      }
    },{
      path: 'rank',
      name: 'rank',
      component: () => import('@/views/jingsai/rank'),
      meta:{
        tag:'zhishijingsai',
        islogin:true
      }
    }]
  },
  {
    path: '/exam',
    component: Layout,
    redirect: '/exam/index',
    children: [{
      path: 'index',
      name: 'Exam',
      component: () => import('@/views/exam/index'),
      meta:{
        tag:'peixunkaoshi',
        islogin:true
      }
    },{
      path: 'datika',
      name: 'Datika',
      component: () => import('@/views/exam/datika/index'),
      meta:{
        tag:'peixunkaoshi',
        islogin:true
      }
    }]
  },
  {
    path: '/train',
    component: Layout,
    redirect:'/train/index',
    children: [{
      path: 'index',
      name: 'Train',
      component: () => import('@/views/train/index'),
      meta:{
        tag:'zhinengpeixun',
        islogin:true
      }
    },{
      path:'detail',
      name:'TrainDetail',
      component: () => import('@/views/train/detail/detail'),
      meta:{
        tag:'zhinengpeixun',
        islogin:true
      }
    }]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
