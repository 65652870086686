<template>
  <div class="container">
    <!-- 导航 -->
    <Nav></Nav>

    <!-- 联系客服和返回顶部 -->
    <!-- <BackTop></BackTop> -->

    <!-- <keep-allive> -->
      <router-view :key="key" />
    <!-- </keep-allive> -->

    <!-- 底栏 -->
    <Footer></Footer>

    <!-- <div v-show="loadingShow" class="loadingMask">
      <van-loading type="spinner" color="orange" />
    </div> -->
    <Login></Login>
  </div>
</template>

<script>

// 引入所有页面的公用组件
    // 引入nav组件
// import Nav from '@/components/nav/nav';
import Nav from '@/components/navbar/index';
    // 引入footer组件
import Footer from '@/components/footer/footer';
// 引入返回顶部，联系客服组件
import BackTop from '@/components/backTop/index';
// 引入登录弹框
import Login from '@/components/login/index';

export default {
  name: 'Layout',
  components: {
    Nav,
    Footer,
    BackTop,
    Login
  },
  data(){
    return {
      loadingShow:false
    }
  },
  created(){
      //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store") ) {
      this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload",()=>{
      sessionStorage.setItem("store",JSON.stringify(this.$store.state))
    })
  },
  mounted(){
    
  },
  methods: {
    
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    }
  }
}
</script>

<style lang="less" scoped>
 *{
    margin:0;
    padding:0;
    box-sizing: border-box;
 }
 .bgImage{
   width:100%;height:100%;
   position: absolute;
   left:0;top:0;
 }
 .container{
    width:100%;
    height:100%;
    position: relative;
  }
</style>
