<template>
    <div class="navbar">
        <div class="leftCon">
            <div class="logo">
                <img class="bgImage" src="@/images/nav/logo.png" alt="" />
            </div>
            <div class="leftNavList">
                <div class="leftNavItem" v-for="(item) in leftNavList" :key="item.id" @click="navbarClick(item.router)" :class="$store.state.navCurrentIndex == item.tag?'navbarItem_active':''">{{item.text}}</div>
            </div>
            <div class="xian"></div>
        </div>
        <div class="rightCon">
            <div class="rightNavList">
                <div class="rightNavItem" v-for="(item) in rightNavList" :key="item.id">
                    <div class="text">{{item.text}}</div>
                    <div class="jiantou iconfont icon-arrow-right"></div>
                    <div class="list">
                        <div class="chidItem" v-for="(subItem,subIndex) in item.list" :key="subIndex" @click="navbarClick(subItem.router)" :class="$store.state.navCurrentIndex == subItem.tag?'chidItem_active':''">
                            {{subItem.text}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn" v-if="!$store.state.loginState" @click="loginClick">注册登录</div>
            <div class="btn btnT" v-else @click="loginOut">退出登录</div>
            <div class="xian"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            leftNavList:[{
                id:1,
                text:"首页",
                router:"/",
                tag:"shouye"
            },
            // {
            //     id:2,
            //     text:"新闻资讯",
            //     router:"/news",
            //     tag:"xinwen"
            // },
            {
                id:3,
                text:"应急访谈",
                router:"/fangtan",
                tag:"fangtan"
            },{
                id:4,
                text:"安全视频",
                router:"/shipin",
                tag:"shipin"
            },{
                id:5,
                text:"安全文库",
                router:"/wenku",
                tag:"wenku"
            },{
                id:6,
                text:"应急装备",
                router:"/zhuangbei",
                tag:"zhuangbei"
            },
            {
                id:7,
                text:"专家答疑",
                router:"/zhuanjia",
                tag:"zhuanjia"
            }
            // {
            //     id:8,
            //     text:"关于我们",
            //     router:"/guanyu",
            //     tag:"guanyu"
            // },{
            //     id:9,
            //     text:"下载中心",
            //     router:"/xiazai",
            //     tag:"xiazai"
            // }
            ],
            rightNavList:[{
                id:1,
                text:"培训",
                list:[{
                    text:"职能培训",
                    router:"/train",
                    tag:"zhinengpeixun"
                },{
                    text:"培训考试",
                    router:"/exam",
                    tag:"peixunkaoshi"
                },{
                    text:"每日答题",
                    router:"/live",
                    tag:"meiridati"
                },{
                    text:"竞赛活动",
                    router:"/jingsai",
                    tag:"zhishijingsai"
                }]
            },{
                id:2,
                text:"课程",
                list:[{
                    text:"课题库",
                    router:"/ketiku",
                    tag:"ketiku"
                },{
                    text:"考试库",
                    router:'/kaoshi',
                    tag:'kaoshi'
                },{
                    text:"国考课",
                    router:"/gkk",
                    tag:"guokaoku"
                }]
            },{
                id:3,
                text:"监管",
                list:[{
                    text:"云端监管",
                    router:"/jianguan",
                    tag:"jianguan"
                }]
            }],
            xyloginUrl:[]
        }
    },
    mounted(){
        // console.log(this.$store.state.navCurrentIndex,'当前Navtag');
        let tag = this.$route.meta.tag;
        this.$store.commit('setNavCurrentIndex',tag);
    },
    computed:{

    },
    methods:{
        navbarClick(path){
            this.$router.push({path});
            // this.$store.commit('setNavCurrentIndex',tag);
        },
        // 登录按钮点击
        loginClick(){
            this.$store.commit('setLoginMaskShow',true);
        },
        // 退出登录
        loginOut(){
            this.$confirm('你确定退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.logout();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        async logout() {
            await this.$store.dispatch('user/logout').then(()=>{
                if(this.$route.path == '/home'){
                    location.reload();
                }else{
                    this.$router.replace('/');
                }
            })
        }
    },
    watch:{
      $route(to,from){
        this.$store.commit('setNavCurrentIndex',to.meta.tag);
      }
    }
}
</script>

<style lang="less" scoped>
.bgImage{
    width:100%;height:100%;
    position: absolute;
    left:0;top:0;
}
.navbar{
    width:100%;height:4vw;
    // background-color: orange;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftCon{
        // width:51.3vw;
        width:42vw;
        height:100%;
        // background-color: aqua;
        padding:0 1.9vw;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        .xian{
            width:1px; height:2.08vw;
            background-color: #cbcbcb;
            position: absolute;
            right:0;top:50%;
            transform: translateY(-50%);
        }
        .logo{
            width:6.09vw;height:2.08vw;
            position: relative;
            margin-right:3vw;
        }
        .leftNavList{
            height:2.08vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background-color: yellow;
            .leftNavItem{
                width:auto;height:1.4vw;
                font-size:1vw;
                color:#707070;
                margin-right:1.25vw;
                cursor:pointer;
            }
            .leftNavItem:nth-last-child(1){
                margin-right:0;
            }
            .navbarItem_active{
                color:#F29600 !important;
            }
        }
    }
    .rightCon{
        width:23.8vw;height:100%;
        // background-color: pink;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:0 2.13vw 0 1vw;
        box-sizing:border-box;
        position: relative;
        .xian{
            width:1px; height:2.08vw;
            background-color: #cbcbcb;
            position: absolute;
            left:0;top:50%;
            transform: translateY(-50%);
        }
        .rightNavList{
            height:2.29vw;
            // background-color: green;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .rightNavItem{
                width:auto;height:4vw;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right:1vw;
                color:#707070;
                cursor:pointer;
                position: relative;
                .text{
                    font-size:0.93vw;
                }
                .jiantou{
                    transition: .3s;
                }
                .list{
                    width:6.6vw;height:0;
                    background-color: #f7f7f7;
                    position: absolute;
                    left:50%;top:4vw;
                    transform: translateX(-50%);
                    z-index:100;
                    transition: 0.3s;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    overflow: hidden;
                    .chidItem{
                        width:100%;height:2vw;
                        font-size:0.93vw;
                        color:#707070;
                        margin-top:0.5vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .chidItem:hover{
                        background-color: #bbbaba;
                    }
                    .chidItem:nth-last-child(1){
                        margin-bottom: 0;
                    }
                    .chidItem_active{
                        color:#F29600 !important;
                    }
                }
            }
            .rightNavItem:nth-last-child(1){
                margin-right:0;
            }
            .rightNavItem:hover .list{
                height:10.8vw !important;
            }
            .rightNavItem:hover .jiantou{
                transform: rotateZ(90deg) !important;
            }
        }
        .btn{
            width:6.97vw;height:2vw;
            background-color: #F29600;
            font-size:0.93vw;
            color:#fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius:1.14vw;
            cursor:pointer;
        }
        .btnT{
            background-color: #ff5857;
        }
    }
}
</style>
<style>
.el-message{
    z-index: 29999 !important;
}
</style>