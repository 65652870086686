import { login, logout } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import store from '..'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    // console.log(token,'vuex保存时，输出token');
    state.token = token
    // console.log(state.token,'zzzz');
  },
  SET_NAME: (state, name) => {
    // console.log(name,'vuex保存时，输出name');
    state.name = name
    // console.log(state.name,'xxxxx');
  },
  SET_AVATAR: (state, avatar) => {
    // console.log(avatar,'vuex保存时，输出avatar');
    state.avatar = avatar
    // console.log(state.avatar,'cccccc');
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { account, password } = userInfo
    return new Promise((resolve, reject) => {
      store.commit('setLoading',true);
      login({ username: account.trim(), password: password }).then(response => {
        if(response.success){
          const result = response.result
          const userInfo = result.userInfo
          commit('SET_TOKEN', result.token)

          commit('SET_NAME', userInfo.realname);
          sessionStorage.setItem('userInfo',JSON.stringify(userInfo));
          sessionStorage.setItem('name',userInfo.realname);

          commit('SET_AVATAR', userInfo.avatar)
          sessionStorage.setItem('avatar',userInfo.avatar);
          setToken(result.token)

          store.commit('setLoading',false);

          resolve(response);
        }else{
          // console.log(this,'this');
          this._vm.$message({
            message:response.message,
            type: 'warning'
          })
          store.commit('setLoading',false);
        }
      }).catch(error => {
        store.commit('setLoading',false);
        reject(error)
        
      })
    })
  },

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const { data } = response

  //       if (!data) {
  //         return reject('Verification failed, please Login again.')
  //       }

  //       const { name, avatar } = data

  //       commit('SET_NAME', name)
  //       commit('SET_AVATAR', avatar)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        // console.log('删除token');
        removeToken() // must remove  token  first
        // resetRouter()
        sessionStorage.removeItem('userInfo');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('avatar');
        commit('RESET_STATE');
        store.commit('setLoginState',false);
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      // console.log('删除token');
      removeToken() // must remove  token  first
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('avatar');
      commit('RESET_STATE');
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
