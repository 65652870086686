import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'   //引入getters
import settings from './modules/settings'
import user from './modules/user'



Vue.use(Vuex)

const store =  new Vuex.Store({
  modules: {
    settings,
    user
  },
  state: {
    navCurrentIndex:'shouye',
    // 登录弹窗是否显示
    loginMaskShow:false,
    // 登录状态（已登录，未登录）
    loginState:false,
    // 登录loading
    loading:false
    // sectionIndex:-1
  },
  mutations: {
    setNavCurrentIndex(state,navCurrentIndex){
      // console.log(navCurrentIndex,'33333');
      state.navCurrentIndex = navCurrentIndex;
    },
    setLoginMaskShow(state,loginMaskShow){
      // console.log(loginMaskShow,'tnnd');
      state.loginMaskShow = loginMaskShow;
    },
    setLoginState(state,loginState){
      // console.log(loginState,'33333');
      state.loginState = loginState;
    },
    setLoading(state,loading){
      // console.log(loading,'33333');
      state.loading = loading;
    }
    // setSectionIndex(state,index){
    //   state.sectionIndex = index
    // }
  },
  getters
})

export default store
