import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import pubmethods from './utils/pubmethods'


Vue.prototype.$pubmethods=pubmethods

Vue.config.productionTip = false

// 完整引入 Element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en'


// 引入vue封装的swiper插件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'  //引入样式
Vue.use(VueAwesomeSwiper)


// 引入iconfont样式
import '@/style/iconfont/iconfont.css'

import '@/permission' // permission control

Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
